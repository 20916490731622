/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogCard from "../components/blog-card"

export const pageQuery = graphql`
  query authorsQuery($id: String!, $authorTitle: String) {
    allMarkdownRemark(
      filter: { frontmatter: { authors: { eq: $authorTitle } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            authors
            description
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 148)
      frontmatter {
        slug
        title
        authorBio
        websiteLink
        authorImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

const Authors = ({ data }) => {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your authors data
  const { frontmatter } = markdownRemark

  const authorCard = allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <BlogCard key={edge.node.id} data={edge.node} />)

  const hasImage = getSrc(frontmatter.authorImage).includes("spacer.png")
    ? false
    : true
  const AuthorImage = hasImage
    ? frontmatter.authorImage.childImageSharp.gatsbyImageData
    : ""
  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        authorBio={frontmatter.authorBio}
        article={true}
      />
      <div sx={authorStyles.authorPageContainer}>
        <div sx={authorStyles.authorContainer}>
          <header sx={authorStyles.authors}>
            <section sx={authorStyles.authorData}>
              <div sx={authorStyles.authorInfo}>
                {frontmatter.title ? (
                  <h2>{frontmatter.title}</h2>
                ) : (
                  "No Authors"
                )}
                {frontmatter.authorBio ? <p>{frontmatter.authorBio}</p> : ""}
                {frontmatter.websiteLink ? (
                  <span>
                    <Link to={frontmatter.websiteLink} target="_blank">
                      Website
                    </Link>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </section>
            {AuthorImage ? (
              <GatsbyImage
                image={AuthorImage}
                alt={frontmatter.title + " - Featured image"}
                className="featured-image"
              />
            ) : (
              ""
            )}
          </header>
          <div>
            {authorCard.length >= 1 ? (
              <h3 sx={{ color: "global.headingColor", mt: 7 }}>
                {authorCard.length} Recent blogs
              </h3>
            ) : (
              ""
            )}
            <div sx={authorStyles.authorBlogs}>{authorCard}</div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Authors

const authorStyles = {
  authorPageContainer: {
    variant: "variants.section",
  },
  authorContainer: {
    variant: "variants.container",
    minHeight: "70vh",
  },
  authors: {
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr 1.8fr"],
    rowGap: "30px",
    alignItems: "center",
  },
  authorData: {
    bg: "transparent",
  },
  authorInfo: {
    bg: "author.bgColor",
    position: "relative",
    left: ["0%", "0%", "30%"],
    zIndex: "1",
    padding: 4,
    h2: {
      m: "0",
      color: "author.textColor",
    },
    p: {
      fontSize: "18px",
      color: "author.mutedColor",
      fontWeight: "300",
    },
    span: {
      a: {
        color: "global.secondarylinkColor",
        "&:hover": {
          color: "global.secondaryLinkHoverColor",
        },
      },
    },
  },
  containerOl: {
    listStyle: "none",
    fontSize: 1,
    color: "#777",
    ml: "-40px",
    li: {
      display: "inline",
      mx: 2,
      "&:first-child": {
        ml: 0,
      },
    },
    a: {
      color: "#333",
      "&:hover": {
        color: "#000",
        textDecoration: "underline",
      },
    },
  },
  authorBlogTitle: {
    mt: 7,
    mb: 5,
  },
  authorBlogs: {
    display: "grid",
    gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"],
    gap: "30px",
  },
}
